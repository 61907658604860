/* .wrapepr{
    min-height: calc(100vh - 170px) ;
} */
.wrapper{
    width: 100%;
    height: auto;
    margin-bottom: 40px;
    font-family: RussianRail_G_Pro;

}

.active_category{
    padding: 10px;
    color: #215cae;
    background-color: #7eccf7;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin: 20px 17px;
}

.category_name{
    padding: 10px;
    color: #285CA6;
    background-color: #A2D9F7;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    margin: 20px 15px;
}
.active_category:hover,
.category_name:hover{
    cursor: pointer;
}

.arrow{
    
    float:right;
    padding-right: 10px;
}

.wrapper_quiz_blocks{

    border: 3px solid #929292;

    max-width:1000px;
    width: 85%;
    height: auto;
    min-height: 200px;

    margin: 40px auto;
    /*padding-bottom: 20px;*/
    text-align: center;

}

.count_team_label_data{
    display: inline-block;
    width: 30px;
    height: 30px;
    color: red;
    margin: 3px 10px;
    background-color: #0288d1;
}

/* https://xn--80adsajtfqq.xn--c1abdmxeng9ge.xn--p1ai/quiz/623acfd275188a4016072deb */

.row_quiz{
    display: inline-block;
    vertical-align: middle;

    padding: 10px 0;

    text-align: center;
    height: auto;
    width: 90%;
    font-size: 0;
    
}

.quiz_empty{

}

