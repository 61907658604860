.wrapper{
    position: fixed;
    width: 60%;

    top:150px;
    background-color: #e7e7e7;
    z-index:110;

    top:120px;
    left: 50%;
    transform: translate(-50%, 5%);

    width:60%;
    max-width: 700px;
    min-height:260px;
    border:3px solid #929292;

}

.content{
    margin-top: 50px;
    padding: 20px;
}

.close_window {

    float: right;
    cursor: pointer;
    color:#929292;
    margin: 10px 25px;
    font-size: 28px;
    z-index: 130;
    font-family: 'Verdana';
}
.href{
    display: block;

    text-decoration: none;
    text-align: center;
    background-color: #E21A1A;
    border: 3px solid #E21A1A;
    box-sizing: border-box;
    max-width: 500px;

    color: #fff;
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    cursor: pointer;
    margin: 20px auto;

    line-height: 25px;

}
@media(max-width:400px){
    .wrapper{
        width: 85%;
        min-height: 350px;
    }
}