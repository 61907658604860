.wrapper{
    position: fixed;
    width: 60%;


    background-color: #A2D9F7;
    z-index:110;

    top:120px;
    left: 50%;
    transform: translate(-50%, 5%);

    width:60%;
    max-width: 700px;
    min-height:230px;
    border:1px solid #285CA6;
    border-radius: 20px;

}

.content{
    margin-top: -10px;
    padding: 5px 20px 20px 20px;
}
.content span{
    display: inline-block;
    font-family: 'Gilroy';
    margin-top: -10px;
    padding-bottom: 5px;
    margin-bottom: 8px;
}

.close_window {

    float: right;
    cursor: pointer;
    color:#285CA6;
    margin: 10px 15px;
    font-size: 20px;
    z-index: 130;
    font-family: 'Gilroy';
}
.modal_background{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index:99;
    background-color: #9b9b9b71;
    top:0;
    left: 0;
}
@media(max-width:400px){
    .wrapper{
        width: 85%;
        min-height: 350px;
    }
}