
.wrapper_window{

    vertical-align: top;
    display: inline-block;
    height: auto;
    min-height: 80px;
    text-align: center;
    width: 100%;

}

.title{
    text-align: center;
    font-family: 'RussianRail_G_Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;

    color: #E21A1A;
    margin-bottom: 17px;
    padding-left: 3px;
}
.team_box_active,
.team_box{
    width: 230px;
    height: 40px;


    text-align: center;
    border: 3px solid #929292;
    box-sizing: border-box;
    cursor: pointer;
    margin: 10px auto;
}

.team_box_active{


    background: rgba(146, 146, 146, 0.16);
}


.team_name{
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    padding-right: 5px;


    color: #000000;
}

.team_scores_disable,
.team_btn_add{
    text-align: center;
    display: inline-block;
    width: 21px;
    height: 21px;
    background: rgba(255, 255, 255, 0.78);
    border: 1px solid #929292;

    font-family: 'Verdana';
    font-weight: 300;
    font-size: 16px;
    color: #000000;
    cursor: pointer;
    line-height: 21px;
    margin-left: 5px;
}
.team_scores_disable{
    background: rgba(255, 255, 255, 0.49);
    cursor: inherit;
}

.team_scores {

    display: inline-block;
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 800;
    font-size: 14px;
    line-height: 20px;
    /* identical to box height */
    margin-left: 10px;
    margin-right: 10px;


    color: #000000;
}
.t_data{
    width: 100%;
    border-spacing: 0;
    border-collapse: collapse;
    height: 100%;
}
.t_teamname{
    width: 50%;
   padding-left: 10px;
    text-align: right;
}

.t_btn_add{
    width: 16%;
    text-align: center;
}
.t_score_add{
    text-align: left;
    width:34%;

}
.btn_cancel{
    width: 125px;
    height: 30px;
    text-align: center;
    background: #3681C3;
    boreder:3px solid #3681C3;
    border-radius: 20px;
    font-family: 'Gilroy';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 28px;
    /* identical to box height */

    float:right;
    color: #FFFFFF;
    margin-top: 20px;
    margin-bottom: 5px;
    margin-right: 3px;
    cursor: pointer;
}
