
  @font-face{
    font-family:"RussianRail_G_Pro";
    src:url(RussianRail_G_Pro_Extend.otf);
    font-weight:400;font-style:normal;font-display:swap
    }

    @font-face{
        font-family:"RussianRail_G_Pro";
        src:url(RussianRail_G_Pro_Extended_Bold.otf);
        font-weight:700;font-style:normal;font-display:swap
    }

    @font-face{
        font-family:"RussianRail_G_Pro";
        src:url(RussianRail-G-Pro_39011.ttf);
        font-weight:200;font-style:normal;font-display:swap
    }