.wrapper{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left:0;
    background-color: rgba(224, 224, 224, 0.6);
    z-index: 10006;
}
.wrapper_result{
    z-index: 10007;
    overflow-y: auto;
    margin: 90px auto;
    position: fixed;
    left: 50%;
    top: 5%;
    /*top:40px;*/

    transform: translateX(-50%);
    background-color: white;
    border:3px solid #929292;
    max-width: 315px;
}

.title{
    font-family: 'RussianRail_G_Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 39px;
    text-align: center;
    color: #E21A1A;
    padding: 39px 30px 20px 30px;
}
.result_table{
    width: 80%;
    border-collapse: collapse;
    margin: 0 auto 0 auto;
    table-layout: fixed;
    word-wrap: break-word;
}
.row{
    text-align: center;
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */
    margin-top: 10px;

    color: #000000;
}
.td_data_level{
    width: 38%;
}
.td_data_name{
    width: 44%;

    text-align: left;
    padding-left: 6px;
}

.td_data_scores{
    width:20%;
}

.btn_close{
    display: block;
    margin:30px auto;
    width: 125px;
    height: 30px;
    text-align: center;
    background: #E21A1A;
    border: 1px solid #E21A1A;

    font-family: 'Verdana';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;

    /* identical to box height */

    cursor: pointer;
    color: #FFFFFF;
}
.btn_close_window{
    position: relative;
    color: #929292;
    background-color: rgba(0, 0, 0, 0);
    border: 1px solid rgba(50, 50, 50, 0);
    font-size: 28px;
    float: right;
    margin-right: 10px;
    cursor: pointer;
}

@media (max-width:339px ) {
    .result_table{
        width: 90%;

    }
    .title{
        font-size: 28px;
    }

}
@media (max-width: 580px) {
    .wrapper_result{
        width: 90%;
    }
}