
.wrapper{

    height: 100px;
    width: 100%;
    background: #ffffff;
    z-index: 10004;
    color: #000000;

}

.logo_rgd{
    display: inline-block;
}

.logo_rgd img{
    height: 100px;
}
.logo{
    margin: 15px 27px;
    display: inline-block;
    vertical-align: top;

}

.logo_up{
    font-family: "RussianRail_G_Pro";
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
}



.logo_down{
    font-family: "RussianRail_G_Pro";
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 48px;
}



.poin_back{
    position:absolute;
    right: 20px;
    top:25px;
    width: 150px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border: 3px solid #E21A1A;
    background-color: #E21A1A;
    color: #fff;
    font-family: 'RussianRail_G_Pro';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;

    cursor: pointer;
}


@media (max-width: 770px){
    .poin_back{
        display: block;
        position: relative;
        top:12px;
        left: 1%;
        /*left: 7px;*/

        margin: 0 auto;
    }
}

@media (max-width: 860px){
    .logo{
        margin: 20px 27px;

    }

    .logo_up{
        font-size: 16px;
        margin-bottom: -10px;
    }

    .logo_down{
        margin-top: -10px;
        font-size: 30px;
    }
}

@media (max-width: 580px){
    .logo{
        margin: 20px 15px;

    }

    .logo_up{
        font-size: 14px;
        margin-bottom: -10px;
    }

    .logo_down{
        margin-top: -10px;
        font-size: 26px;
    }
}

@media (max-width: 526px){
    .wrapper{

        height: 60px;

    }
    .logo_rgd{
        height: 60px;
    }
    .logo{
        margin: 3px 20px;

    }

}

@media (max-width: 466px){

    .poin_back{
        display: block;
        position: relative;
        top:-15px;
        left: 1%;
        /*left: 7px;*/

        margin: 0 auto;
    }
    .logo{
        margin: 7px 0 30px 10px ;

    }

    .logo_up{
        font-size: 11px;
        margin-bottom: -18px;
    }

    .logo_down{
        margin-top: -10px;
        font-size: 19px;
    }
}
@media (max-width: 340px){
    .logo{
        margin: 7px 0 30px 10px ;

    }

    .logo_up{
        font-size: 9px;
        margin-bottom: -18px;
    }

    .logo_down{
        margin-top: -10px;
        font-size: 16px;
    }
}
@media (max-width: 310px){
    .logo{
        margin: 7px 0 30px 10px ;

    }

    .logo_up{
        font-size: 8px;
        margin-bottom: -18px;
    }

    .logo_down{
        margin-top: -10px;
        font-size: 14px;
    }
}

