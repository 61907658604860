.wrapper{
    z-index: 10007;
    overflow-y: auto;
    margin: 90px auto;
    position: fixed;
    left: 50%;
    top: 5%;
    /*top:40px;*/
    width: 80%;
    transform: translateX(-50%);
    background-color: white;
    border:3px solid #929292;
    max-width: 700px;
    padding: 10px;
}

.background{
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    background-color: rgba(224, 224, 224, 0.6);
    z-index: 10006;
}
.text{
    font-family: 'Verdana';
    font-weight: 800;
    color: #E21A1A;
    font-size: 16px;

    padding: 10px;
    margin:  auto;
    text-align: center;
}
.btn_wrapper{
    margin: auto;
    text-align: center;
}
.btn_next,
.btn_close{
    background: #E21A1A;
    border: 3px solid #E21A1A;
    box-sizing: border-box;
    width: 210px;
    height: 30px;
    margin: 10px 10px;

    font-family: 'Verdana';
    font-style: normal;
    font-weight: 800;
    font-size: 16px;


    text-align: center;

    color: #fff;
    cursor: pointer;
    display: inline-block;
}

.btn_close{
    background: #fff;
    color: #E21A1A;
}

