.count_wrapper{
    display: inline-block;
    text-align: center;
    vertical-align:middle;
    margin-top: 10px;

    min-height: 30px;
    margin-bottom: 15px;

}

.wrapper_is_count{
    display: inline-block;
    vertical-align:middle;
    margin-right: 15px;

    height: 30px;

}

.task_score_checkbox{
    display: inline-block;
    width: 20px;
    height: 20px;
    vertical-align: middle;
    background: #fff;
    color: #333;
}

.task_score{
    display: inline-block;
    margin-right: 10px;
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    vertical-align:middle;

    color: #000000;

}

.count_team{
    font-family: 'Verdana';
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 14px;
    margin: 0 10px;
    height: auto;
    vertical-align:middle;
}

.wrapper_count_team{
    display: inline-block;
    vertical-align:middle;
    height: 30px;
}

.count_team_label{
    display: inline-block;
    font-family: 'Verdana';
    font-style: normal;
    line-height: 30px;
    font-weight: 300;
    font-size: 14px;
    vertical-align:middle;
}

.wrapper_count_team_add{
    display: inline-block;
    vertical-align:middle;
    margin-left: 5px;
    font-weight: 300;
    margin-right: 5px;
    font-family: 'Verdana';
    vertical-align: middle;
    width: 103px;
    text-align: center;
}


.count_team_label_add{
    display: inline-block;
    text-align: center;
    font-family: 'Verdana';
    vertical-align:middle;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;

    color: #000000;

    width: 20px;
    height: 20px;
    left: 816px;
    top: 150px;

    background: rgba(255, 255, 255, 0.81);
    border: 1px solid #929292;
}


